<template>
  <div class="bill__buttons bill__inner">
    <template v-if="!isInvestor">
      <sdButton
        class="bill__button"
        @click="$emit('openModal', 'publish')"
        size="default"
        type="secondary"
        style="margin-bottom: 0"
        raised
        v-if="account.owner"
      >
        {{ i18n.t(`accounts.trading.unpublish`) }}
      </sdButton>
      <sdButton class="bill__button" @click="$emit('openModal', 'invest')" size="default" type="primary" raised v-else>
        {{ i18n.t('accounts.public.invest') }}
      </sdButton>
    </template>
    <template v-else>
      <sdButton
        class="bill__button"
        @click="$emit('openModal', 'close')"
        :disabled="isClosed"
        size="default"
        type="secondary"
        style="margin-bottom: 0"
        raised
      >
        {{ i18n.t(`accounts.invest.close`) }}
      </sdButton>
      <sdButton
        class="bill__button"
        @click="$emit('openModal', 'invest', 'withdrawal')"
        size="default"
        type="primary"
        :disabled="isClosed"
        raised
      >
        {{ i18n.t('accounts.invest.withdrawal') }}
      </sdButton>
      <sdButton
        class="bill__button"
        @click="$emit('openModal', 'invest', 'deposit')"
        :disabled="isClosed"
        size="default"
        type="success"
        raised
      >
        {{ i18n.t('accounts.invest.deposit') }}
      </sdButton>
    </template>
  </div>
</template>

<script>
import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';
export default {
  emits: ['openModal'],
  props: {
    isInvestor: {
      type: Boolean,
      default: false,
    },
    account: {
      type: Object,
      default() {
        return {};
      },
    },
    isClosed: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const i18n = reactive(useI18n());

    return { i18n };
  },
};
</script>

<style scoped>
button {
  width: 100%;
  margin-top: 0 !important;
}
.bill__buttons {
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
  /*padding-bottom: 20px;*/
}
</style>
